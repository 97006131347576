@use '../../colors';
@use 'buttons.scss';
@use 'tables.scss';
@use 'select.scss';
@use '../blaze-theme/blaze-colors.scss';

.ant-modal {
  color: blaze-colors.$black;
}

// Don't show horizontal scrollbar unless the class name contains `show-horizontal-scrollbar`
.ant-table-body {
  scrollbar-width: none; // Firefox

  // Chrome & Safari
  &::-webkit-scrollbar {
    display: none;
  }
}

// Only show the horizontal scroll bar when there is at least 1 line
.show-horizontal-scrollbar {
  .ant-table-body {
    scrollbar-width: thin; // Firefox

    // Chrome & Safari
    &::-webkit-scrollbar {
      display: block;
      height: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: grey;
      border-radius: 100px;
    }
  }
}

.ant-table.ant-table-small {
  .ant-table-body {
    margin: 0;
  }
}

.ant-shadow {
  -webkit-box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05)
}

.gr-popover {
  min-width: 240px;

  .ant-popover-inner-content {
    width: 100%;
    min-width: 240px;
  }
}

.centerized-tab {
  .ant-tabs-nav-scroll {
    display: flex;
    justify-content: center;
  }
}

.ant-message {
  z-index: 1051;
}

// Remove steppers from antd inputNumber
.ant-input-number-handler-wrap {
  visibility: hidden
}

/**
 * Alert
 */
.ant-alert-message {
  color: blaze-colors.$black;
}

/**
 * Table
 */
.ant-table {
  color: blaze-colors.$black;
}

.ant-table-thead>tr>th {
  color: blaze-colors.$black;
}

.ant-table-footer {
  color: blaze-colors.$black;
}

/**
 * Overriding rgba(0, 0, 0, 0.65) to $mono55
 */
.ant-timeline,
.ant-typography,
.ant-dropdown-menu-item,
.ant-select-dropdown-menu-item,
.ant-dropdown-menu-submenu-title,
.ant-checkbox-wrapper {
  color: blaze-colors.$mono55;
}

.ant-selected-item {
  font-weight: bold;
  background-color: blaze-colors.$monoFa
}

/**
  * Radio
  */  
.batch-radio-item {
  &.selected {
    border-color: blaze-colors.$retailAlt;
    color: blaze-colors.$retailAlt;
  }

  & .ant-radio-checked {
    & .ant-radio-inner {
      border-color: blaze-colors.$retailAlt;

      &::after {
        background-color: blaze-colors.$retailAlt;
      }
    }
  }

  & .ant-radio-wrapper:hover .ant-radio,
  & .ant-radio:hover .ant-radio-inner,
  & .ant-radio-input:focus + .ant-radio-inner {
    border-color: blaze-colors.$retailAlt;
  }
}

.ant-select.show-close-icon {
  .ant-select-selection {
    .ant-select-selection__clear {
      opacity: 1;
      margin-right: 20px;
    }
  }
}
