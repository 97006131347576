@use '../../colors.scss';
@use '../../styles/blaze-theme/blaze-colors.scss';

#pos-login {
  background-color: blaze-colors.$black;
  height: 100vh;
  padding: 5%;
  
  @media screen and (max-width: 768px) {
    height: 100%;
    padding-bottom: 70%;
    padding-left: 15%;
  }
}

#pos-menu {
  animation-duration: 200ms;
  -webkit-animation-duration: 200ms;
  -moz-animation-duration: 200ms;
}

.pos-navbar {
  .nav-item {
    display: flex;
    align-items: center;
  }
}

.sub-cat-btn:hover {
  border: 1px solid #DFDFDF
}

.ant-form-item-has-error .ant-form-item-explain,
.ant-form-item-has-error .ant-form-item-split {
  color: #ff4d4f;
}

.ant-form-item-has-error .ant-input,
.ant-form-item-has-error .ant-input-affix-wrapper,
.ant-form-item-has-error .ant-input:hover,
.ant-form-item-has-error .ant-input-affix-wrapper:hover {
  border-color: #ff4d4f;
}

.warning-msg span {
  color: colors.$red300;
}

.word-wrap {
  word-wrap: break-word;
}

#shift-login-input:focus-visible {
  outline: blaze-colors.$retailAlt solid 1px !important;
  box-shadow: 0 0 0.5px 0.5px blaze-colors.$retailAlt !important;
}

#shift-login-input:hover {
  border: 0.5px solid blaze-colors.$retailAlt !important;
  box-shadow: 0 0 0 0.5px blaze-colors.$retailAlt !important;
}

#shift-login-input:focus {
  outline: blaze-colors.$retailAlt solid 1px !important;
  box-shadow: 0 0 0.5px 0.5px blaze-colors.$retailAlt !important;
  border: 1px solid blaze-colors.$retailAlt !important;
  border-radius: 4px !important;
}