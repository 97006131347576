.login-page {
  #root {
    height: 100%;
    > div {
      height: 100%
    }
  }
}


.login-form {
  min-height: 370px;
  padding: 2rem;

  @media (min-width: 992px) {
    max-width: 450px;
  }
}

.continue-session-btn {
  height: auto;
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: left;
}

@media (max-width: 375px) {
  .continue-session-btn {
    width: 100%;
  }
}

.animated-gradient-bg {
  background: linear-gradient(230deg, #455A64, #263238, #37474F);
  background-size: 600% 600%;
  
  animation: GradientAnimation 20s ease infinite;
}

@keyframes GradientAnimation {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
