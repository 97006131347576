// Stacked Icons
// -------------------------
@use "variables";

.#{variables.$fa-css-prefix}-stack {
  position: relative;
  display: inline-block;
  width: 2em;
  height: 2em;
  line-height: 2em;
  vertical-align: middle;
}
.#{variables.$fa-css-prefix}-stack-1x, .#{variables.$fa-css-prefix}-stack-2x {
  position: absolute;
  left: 0;
  width: 100%;
  text-align: center;
}
.#{variables.$fa-css-prefix}-stack-1x { line-height: inherit; }
.#{variables.$fa-css-prefix}-stack-2x { font-size: 2em; }
.#{variables.$fa-css-prefix}-inverse { color: variables.$fa-inverse; }
