/* FONT PATH
 * -------------------------- */
 @use "variables";

@font-face {
  font-family: 'FontAwesome';
  src: url('#{variables.$fa-font-path}/fontawesome-webfont.eot?v=#{variables.$fa-version}');
  src: url('#{variables.$fa-font-path}/fontawesome-webfont.eot?#iefix&v=#{variables.$fa-version}') format('embedded-opentype'),
    url('#{variables.$fa-font-path}/fontawesome-webfont.woff2?v=#{variables.$fa-version}') format('woff2'),
    url('#{variables.$fa-font-path}/fontawesome-webfont.woff?v=#{variables.$fa-version}') format('woff'),
    url('#{variables.$fa-font-path}/fontawesome-webfont.ttf?v=#{variables.$fa-version}') format('truetype'),
    url('#{variables.$fa-font-path}/fontawesome-webfont.svg?v=#{variables.$fa-version}#fontawesomeregular') format('svg');
//  src: url('#{variables.$fa-font-path}/FontAwesome.otf') format('opentype'); // used when developing fonts
  font-weight: normal;
  font-style: normal;
}
