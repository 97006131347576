/*!
 * Start Bootstrap - Simple Sidebar (http://startbootstrap.com/)
 * Copyright 2013-2016 Start Bootstrap
 * Licensed under MIT (https://github.com/BlackrockDigital/startbootstrap/blob/gh-pages/LICENSE)
 */
@use '../../colors';
@use '../../styles/blaze-theme/blaze-colors.scss';

$borderColor: #e7e7e7;
$hoverBackgroundColor: #333;
$textColor: white;
$sidebarWidth: 200px;

.stats-card {
  background-color: #3C3F47 !important;
}

/* Toggle Styles */

#wrapper {
  
  padding-left: 0;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;

  &.toggled {
    padding-left: $sidebarWidth;
  }

  /* Sidebar Styles */

  .sidebar-nav {
    position: relative;
    top: 0;
    width: $sidebarWidth;
    margin: 0;
    padding: 0;
    list-style: none;
  }

}

#sidebar-wrapper {
  z-index: 8;
  position: fixed;
  left: $sidebarWidth;
  width: 0;
  height: 100%;
  margin-left: -$sidebarWidth;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
  border-color: $borderColor;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;

  .ant-menu-dark {
    background-color: blaze-colors.$blazeBlackSideNav;
    color: blaze-colors.$monoFf;

    :hover {
      background-color: blaze-colors.$black;
      transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
    }

    .ant-menu-item {
      margin-top: 0;
      margin-bottom: 0;
      height: 47px;
      line-height: 47px;

      > a {
        color: blaze-colors.$monoFf;
      }
    }

    .ant-menu-item-selected {
      background-color: blaze-colors.$black;
    }

    .ant-menu-sub {
      background-color: blaze-colors.$blazeBlackSideNav;
      box-shadow: none;
    }

    .ant-menu-submenu-open {
      background-color: blaze-colors.$black;
    }
  }
}

#sidebar-wrapper::-webkit-scrollbar {
  width: 0
}

#wrapper.toggled #sidebar-wrapper {
  width: $sidebarWidth;
}

#page-content-wrapper {
  width: 100%;
  // position: absolute;
}

#wrapper.toggled #page-content-wrapper {
  position: absolute;
  margin-right: -$sidebarWidth;
}

.page-content-wrapper__inner {
  height: inherit;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  // padding: 2rem 1rem 0 2rem;
}

@media(min-width: 768px) {
  #wrapper {
    padding-left: $sidebarWidth;
  }

  #wrapper.toggled {
    padding-left: 0;
  }

  #sidebar-wrapper {
    width: $sidebarWidth;
  }

  #wrapper.toggled #sidebar-wrapper {
    width: 0;
  }
  //---- insights navigation---------------
  #wrapper.hidden #sidebar-wrapper {
    width: $sidebarWidth !important;
  }

  #wrapper.hidden {
    padding-left: $sidebarWidth !important;
  }

  #wrapper.hidden #page-content-wrapper {
    position: relative;
    margin-right: 0;
  }

  // ---------------------------
  #page-content-wrapper {
    position: relative;
    
    .page-content-wrapper__padding {
      padding: 2rem 1rem 0 2rem;
      overflow: auto;
    }
  }

  #wrapper.toggled #page-content-wrapper {
    position: relative;
    margin-right: 0;
  }
}

#wrapper.hidden #sidebar-wrapper {
  width: 0;
}

#wrapper.hidden {
  padding-left: 0;
}

#wrapper.hidden #page-content-wrapper {
  position: relative;
  margin-right: 0;
}

@media(max-width: 768px) {
  .expanded {
    #sidebar-wrapper {
      width: $sidebarWidth;
    }
  }

  #page-content-wrapper {
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    transition: all 0.2s ease;
    #expand-button {
      cursor: pointer;
      padding-right: 1rem;
      .expanded & {
        -webkit-transition: all 0.2s ease;
        -moz-transition: all 0.2s ease;
        -o-transition: all 0.2s ease;
        transition: all 0.2s ease;
        margin-left: $sidebarWidth;
      }
    }
  }


}

.table-responsive {
  overflow: auto;
  overflow-x: auto;
}

.partner-logo-img {
  height: 40px;
  max-width: 120px;
  object-fit: contain;
  cursor: pointer;
}

.banner-message p {
    margin-bottom: 0;
}