@use '../colors.scss';
@use 'breakpoints.scss';
@use 'custom-scrollbar.scss';
@use 'bootstrap-override.scss';
@use 'reactstrap-override.scss';
@use 'custom-filter.scss';
@use 'custom-input.scss';
@use 'custom-table.scss';
@use 'ant-design/ant-global.scss';
@use 'blaze-theme/blaze-colors.scss';

// Import google font
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

html {
  @media(max-width: 400px) {
    font-size: 14px;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
  }
}

h1, h2, h3, h4, h5, h6 {
  color: blaze-colors.$black;
}

// Make a links without hrefs look like links
a {
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }

  &:not([href]):not([tabindex]) {
    color: blaze-colors.$retailAlt;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.disable-div {
  pointer-events: none;
}

img {
  object-fit: cover;
}

.primary {
  color: colors.$primaryColor;
}

.secondary {
  color: colors.$primaryColor;
}

.background-error {
  background-color: colors.$red;
  color: white;
}

.background-warn {
  background-color: colors.$orange;
}

.background-white {
  background-color: white;
}

.background-grey {
  background-color: colors.$background-grey;
}

.background-shadow {
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.25), 0 5px 5px rgba(0, 0, 0, 0.22);
}

.table-row-background-error {
  background-color: rgba(255, 0, 0, 0.1);
}

.ant-table-tbody > tr.table-row-background-error:hover > td {
  background: rgba(255, 0, 0, 0.1) !important
}

.feature-card {
  @extend .background-shadow;
  padding: 1em;
  height: 100%;
}

.dark-theme {
  background-color: colors.$secondary-dark;
  color: white;
}

.bg-success.faded {
  background-color: colors.$primaryFaded  !important;
}

.full-height {
  height: 100vh;
  // transition: height 9999999999999999999999s;
}

.full-view {
  height: 100%;
  width: 100%;
}

.absolute-top-left {
  position: absolute;
}

.absolute-top-right {
  position: absolute;
  top: 1em;
  right: 1em;
}

// Prevent react-select dropdown menu from being overlapped by other inputs
.Select-menu-outer {
  z-index: 9;
}

// Support for extra wide modals (but if this is needed, it should probably be it's own page)
// Reference: http://stackoverflow.com/questions/25859255/trying-to-make-bootstrap-modal-wider
@media (min-width: 768px) {
  .modal-xl {
    width: 90%;
    max-width: 1200px;
  }
}

// Speed up the bootstrap modal slide-in speed
.fade {
  opacity: 0;
  -webkit-transition: opacity 0s linear;
  -moz-transition: opacity 0s linear;
  -ms-transition: opacity 0s linear;
  -o-transition: opacity 0s linear;
  transition: opacity 0s linear;
}

.modal.fade:not(.in).right .modal-dialog {
  -webkit-transform: translate3d(10%, 0, 0);
  transform: translate3d(10%, 0, 0);
}

.react-toggle {
  vertical-align: middle;
  margin-right: 1em;
}

// Bootstrap pagination is unresponsive by default. Allow the pagination entries to wrap.
.pagination {
  display: flex;
  flex-wrap: wrap;
}

// Reference: https://stackoverflow.com/questions/22199429/bootstrap-grid-for-printing
@media print {

  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12 {
    float: left;
  }

  #page-content-wrapper {
    height: auto !important;
  }

  .col-sm-12 {
    width: 100%;
  }

  .col-sm-11 {
    width: 91.66666667%;
  }

  .col-sm-10 {
    width: 83.33333333%;
  }

  .col-sm-9 {
    width: 75%;
  }

  .col-sm-8 {
    width: 66.66666667%;
  }

  .col-sm-7 {
    width: 58.33333333%;
  }

  .col-sm-6 {
    width: 50%;
  }

  .col-sm-5 {
    width: 41.66666667%;
  }

  .col-sm-4 {
    width: 33.33333333%;
  }

  .col-sm-3 {
    width: 25%;
  }

  .col-sm-2 {
    width: 16.66666667%;
  }

  .col-sm-1 {
    width: 8.33333333%;
  }
}

// https://stackoverflow.com/questions/24658365/img-tag-displays-wrong-orientation
// Fix Jesse's sideways images
img {
  image-orientation: from-image;
}

@media (max-width: 1365px) {
  .hidden-768p {
    display: none;
  }
}

// Otherwise the datepicker gets overridden by other nearby inputs
.react-datepicker-popper {
  z-index: 99;
}

// Fix timepicker css
.react-datepicker__time-list {
  padding: 0px;
}

.react-datepicker__time-list-item {
  padding: 5px 3px !important;
}

// Wrap the text in buttons
.text-wrap {
  white-space: normal;
}

// Reference: https://github.com/daneden/animate.css/blob/master/animate.css
// Don't use full animate.css because it causes global POS css issues

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft;
}

.flex-center {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-fill {
  flex: 1
}

.narrow-container {
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
}

body {
  font-family: 'Roboto', sans-serif;
  font-size: 90%;
  color: blaze-colors.$black;
}

.dropzone {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  width: 100%;
  height: 5rem;
  border-width: 2px;
  border-style: dashed;
  border-radius: 5px;

  &>* {
    text-align: center;
  }

  &:hover {
    border-width: 4px;
  }
}

.highlighted-text {
  background-color: colors.$highlighted-text;
  padding: 0;
}

.center {
  justify-content: center
}

.italic {
  font-style: italic;
}

.inline {
  display: inline;
}

.primary-font-size {
  font-size: 1rem;
}

.primary-font-black {
  font-size: 1rem;
  color: colors.$primaryFont;
}

ul.no-margin-list {
  margin-bottom: 0;
}

@media print {

  #sidebar-wrapper,
  .app-sidebar {
    display: none;
  }

  #wrapper {
    padding-left: 0;
  }
}

.spaced-button-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -5px; // Add positive margin-bottom in each item and negative for the wrapper 

  &>* {
    margin-right: 5px;
    margin-bottom: 5px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.ant-btn-circle {
  display: flex;
  justify-content: center;
  align-items: center;

  &>.anticon {
    display: flex;
  }
}

.intercom-pb-standard {
  padding-bottom: 92px !important;
}

.intercom-pb-1 {
  padding-bottom: 20px;
}

.intercom-pb-2 {
  padding-bottom: 88px;
}

.intercom-pb-3 {
  padding-bottom: 130px;
}

.intercom-pb-4 {
  padding-bottom: -900px;
}

.intercom-mb-1 {
  margin-bottom: 60px;
}

.gr-link {
  &>.ant-tag {
    cursor: pointer;
  }

  &:hover {
    text-decoration: underline;
  }

  &:hover>.ant-tag {
    text-decoration: underline;
  }
}

.white-rounded-container {
  padding: .5rem;
  margin-bottom: 1rem;
  background-color: white;
  border-radius: 4px;

  &:last-child {
    margin-bottom: 0;
  }
}

.caption {
  margin: 4px;
  font-size: 12px;
  color: blaze-colors.$blackAlt;
}

.caption.error {
  color: var(--red)
}

.gr-sales-notes {
  white-space: pre-wrap;
}

.hide-scrollbar::-webkit-scrollbar {
  display: block;
  height: 0;
}

.table-col-truncate {
  max-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.white-space-pre-wrap {
  white-space: pre-wrap;
}

.blaze-text {
  &-default {
    color: blaze-colors.$black !important;
  }

  &-muted {
    color: blaze-colors.$blackAlt !important;
  }

  &-danger {
    color: blaze-colors.$dangerAlt !important;
  }

  &-warning {
    color: blaze-colors.$distroAlt !important;
  }

  &-primary {
    color: blaze-colors.$retailAlt !important;
  }
}

// The following 2 classes are for having a dark tooltip on a disabled button
// only used in ExpandedPayment.tsx currently
button.ant-btn.ant-btn-primary.darkTooltip:disabled {
  opacity: 1 !important;
  background-color: rgba(blaze-colors.$retailAlt, 0.4);
  border-color: rgba(blaze-colors.$retailAlt, 0);
}

button.ant-btn.blz-btn-danger.darkTooltip:disabled {
  opacity: 1 !important;
  background-color: rgba(blaze-colors.$danger, 0.4);
  border-color: rgba(blaze-colors.$danger, 0);
}

.reactstrap-dropdown-menu {
  max-height: 200px;
  overflow-y: auto;
  background: blaze-colors.$monoFf;
}
