@use '../blaze-theme/blaze-colors.scss';
@use '../../colors.scss';

// Override "Select" component for AntDesign v4
.antd-override-select {
  .ant-select-selector {
    color: blaze-colors.$black;
    border-radius: 4px;
  }

  &, &.ant-select-single:not(.ant-select-customize-input) {
    .ant-select-selector {
      border-radius: 4px;
    }  
  }

  &.ant-select-focused:not(.ant-select-disabled) {
    &.ant-select-single:not(.ant-select-customize-input),
    &.ant-select-multiple {
      & .ant-select-selector {
        border-color: blaze-colors.$retailAlt;
        -webkit-box-shadow: 0 0 0 2px rgba(blaze-colors.$retailAlt, 0.2);
                box-shadow: 0 0 0 2px rgba(blaze-colors.$retailAlt, 0.2);
      }
    }
  }

  &.ant-select:not(.ant-select-disabled):hover {
    & .ant-select-selector {
      border-color: blaze-colors.$retailAlt;
    }
  }


  .ant-select-item-option-active {
    &:not(.ant-select-item-option-disabled) {
      background-color: colors.$blaze-ant-select-background-active;
    }
  }
}


/* 
 * NOTE: These styles are hacks.
 *
 * Forcing styles here. The app currently does not have ant-design 4
 * custom theme setup. the set-up for that seems complicated. There is better
 * support if we use less, but that is going to complicate
 */
 .product-dropdown-list{
  margin-top: 16px;
 .filter-dropdown-input-wrapper{
    position:relative;
    .ant-select-selector{
      padding: 0 !important;
      margin: 0 !important;
    }
    border-bottom: 1px solid blaze-colors.$monodd;
    &:hover {
      border-bottom: 1px solid blaze-colors.$retailAlt;
    }    .ant-select-selection-placeholder{
      left: 0 !important;
    }
    input{
      margin-left: 0 !important;
    }
  }
}

.filter-dropdown-input {
  &:hover{
    &>.ant-select-selector{
      border-color: blaze-colors.$retailAlt !important;
    }
  }
  &.ant-select-focused{
    &>.ant-select-selector{
      border-color:blaze-colors.$retailAlt !important;
      box-shadow: 0 0 0 2px rgba(blaze-colors.$retailAlt, 0.2) !important;
    }
  }
  &>.ant-select-selector{
    border-radius: 4px !important;
  }
}
