@use '../../styles/global.scss';
@use '../../../src/colors.scss';

.app-view {
  overflow-x: hidden;
  height: 100%;
  width: 100%;
  display: flex;
  flex: 1;

  &.column {
    flex-direction: column;
  }
}

.app-view-wrapper {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
}

.app-sidebar {
  max-width: 16rem;
  min-width: 10rem;
  flex: 0 0 25%;
  display: flex;
  flex-direction: column;
  border-right: 1px solid colors.$lightBlackAlpha;
  @extend .background-shadow;

  .app-sidebar__content {
    flex: 1 1 auto;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }

}

.app-sidebar__header {
  flex: 0 0 auto;

  .app-sidebar-actions__btn {
    display: block;
    border: 0;
    outline: 0;
    width: 100%;
    padding: 0.5rem;
  }
}

#app-main-view {
  padding: 1rem;
  flex: 1;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  position: relative;

  .btn__show-all {
    // Button that looks like <a>
    color: #007BFF;
    background: unset;
    margin: 0 8px 0 0;
    padding: 0;
    border: 0;
    cursor: pointer;
  }
}


.app-sidebar-item {
  border-bottom: 1px solid colors.$lightBlackAlpha;
  border-top: 1px solid transparent;
  font-size: 0.8rem;
  padding: 1rem;
  cursor: pointer;
  transition: all 0.2s ease;

  .app-sidebar-item__date {
    color: colors.$mediumGrey;
  }

  .app-sidebar-item__title {
    font-size: 1.2em;
    font-weight: 500;
    color: colors.$primaryFont;
  }

  &.active {
    background: colors.$lightBlackAlpha;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15), 0 5px 10px rgba(0,0,0,0.1);
  }
}