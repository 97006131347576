// List Icons
// -------------------------
@use "variables";

.#{variables.$fa-css-prefix}-ul {
  padding-left: 0;
  margin-left: variables.$fa-li-width;
  list-style-type: none;
  > li { position: relative; }
}
.#{variables.$fa-css-prefix}-li {
  position: absolute;
  left: calc(-1 * variables.$fa-li-width);
  width: variables.$fa-li-width;
  top: calc(2em / 14);
  text-align: center;
  &.#{variables.$fa-css-prefix}-lg {
    left: calc(-1 * variables.$fa-li-width) + calc(4em / 14);
  }
}
