@use 'blaze-theme/blaze-colors.scss';

// Variables
$filter-item-margin-right: 8px;
$filter-item-margin-bottom: 8px;
$filter-item-width: 200px;
$filter-column-item-width: 58px;

$filter-item-close-button-size: 12px;
$filter-item-close-button-padding: 9.8px;


.gr-filter {
  &-item-wrapper {
    margin-right: $filter-item-margin-right;
  }

  &-item {
    width: $filter-item-width;
  }

  .ant-dropdown-menu-item {
    background: none !important;
  }

  &-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 2px solid #d9d9d9;
    color: #000000a6;
    padding-bottom: 2px;
  }

  &-button:hover {
    border: 2px solid blaze-colors.$retail;
  }

  &-column-item {
    width: $filter-column-item-width;
    padding-right: 16px !important;
  }

  &-gutter {
    margin-right: $filter-item-margin-right;
    margin-bottom: $filter-item-margin-bottom;
  }

  &-close-button {
    font-size: $filter-item-close-button-size;
    padding: $filter-item-close-button-padding;
  }
}
