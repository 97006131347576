#loading-animation {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  &:not(.no-padding) {
    padding-top: 3em;
    padding-bottom: 3em;
  }
}
