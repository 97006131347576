// Brand colours
$primaryColor: #01FE01;
$primaryFaded: #96D196;
$secondaryColor: #007500;

// UI Dark
$primary-dark: #24262D;
$secondary-dark: #2F323A;
$tertiary-dark: #313442;

// Text
$primaryFont: #212529;

$mediumGrey: #868e96;
$lightGrey: #c9d2db;

$offWhite: #FAFAFA;

$orange: #F57C00;

$red: #d9534f;
$red300: #e57373;

$lightBlackAlpha: rgba(0,0,0,.1);

$border-grey: rgba(0, 0, 0, 0.125);

$border-lightgrey: #dfdfdf;

$background-grey: rgba(0,0,0,.075);

$highlighted-text: #ffc069;

$greenline-green: #43a047; // or #28a745
$greenline-green-active: #65ad66;
$greenline-green-selected-bg: #d5e0d3;

$ant-border-grey: #e8e8e8;

$blaze-ant-select-background-active: #e6f7ff;