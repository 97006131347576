#register {

  .animated {
    -animation-duration: 0.2s;
  }

  .container {
    padding-top: 5em;
    padding-bottom: 5em;
  }

}
