@use 'blaze-theme/blaze-colors.scss';
@use '../colors.scss';

// http://stackoverflow.com/questions/34450586/justify-buttons-with-bootstrap-v4
.btn-group-justified {
  display: flex;
  width: 100%;
  .btn,
  .btn-group {
    flex: 1;
    .btn {
      width: 100%;
    }
    .dropdown-menu {
      left: auto;
    }
  }
}

.btn {
  cursor: pointer;
}

// Vertically center all items in table rows
// Reference: https://stackoverflow.com/questions/25932622/bootstrap3-vertical-center-align-table-content

.table > tbody > tr > td {
  vertical-align: middle;
}

.table.regular > tbody > tr > td {
  vertical-align: top;
}

// Borderless tables
// Reference: https://stackoverflow.com/questions/18075794/bootstrap-table-without-stripe-borders/24830641
.table-borderless > tbody > tr > td,
.table-borderless > tbody > tr > th,
.table-borderless > tfoot > tr > td,
.table-borderless > tfoot > tr > th,
.table-borderless > thead > tr > td,
.table-borderless > thead > tr > th {
    border: none;
}

// Color taken from Hubspot
.thead-default tr th {
  background-color: #EAF0F6;
  font-weight: 500;
  padding: .5rem;
}

// Reference: https://stackoverflow.com/questions/15643037/how-do-i-change-the-hover-over-color-for-a-hover-over-table-in-bootstrap
.table-hover tbody tr:hover td, .table-hover tbody tr:hover th {
  background-color: #f2f4f7;
}

.thead-default.thick {
  font-size: 1.2em;
  th {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
  }
}

.btn-invisible {
  border-color: transparent;
  background-color: inherit;
  cursor: pointer;
}

.bg-success--active {
  background-color: colors.$secondaryColor !important;
}

.ant-form-item-label {
  line-height: inherit;
}

.ant-form-item-label > label::after {
  display: none
}

.ant-form-item-label > label {
  margin-bottom: 0;
  margin-right: 1rem;
  line-height: 2.5;
}

.ant-layout-header .ant-menu {
  line-height: inherit;
}

.form-control {
  border-color: blaze-colors.$monocc;
  color: blaze-colors.$black;

  &:focus {
    border-color: blaze-colors.$retailAlt;
    box-shadow: 0 0 0 2px rgba(blaze-colors.$retailAlt, 0.2);
  }
}

.input-group-text {
  background-color: blaze-colors.$monoee;
  color: blaze-colors.$black;
  border-color: blaze-colors.$monocc;
}

.border-primary {
  border-color: blaze-colors.$retailAlt !important;
}

/**
 * Buttons
 */
.btn {
  &.btn-light {
    background-color: blaze-colors.$monoFa;
    border-color: blaze-colors.$monodd;

    &:hover {
      background-color: blaze-colors.$monocc;
      border-color: blaze-colors.$monocc;
    }

    &.buttonv3--primary {
      border-color: blaze-colors.$retailAlt;
      color: blaze-colors.$retailAlt;
    }
  }

  &.btn-primary {
    background-color: blaze-colors.$retailAlt;
    border-color: blaze-colors.$retailAlt;

    &:hover, 
    &:not(:disabled):not(.disabled):active {
      background-color: blaze-colors.$retail;
      border-color: blaze-colors.$retail;
    }

    &:focus {
      box-shadow: 0 0 0 2px rgba(blaze-colors.$retailAlt, 0.2);
    }

    &.btn-lg:focus {
      box-shadow: 0 0 0 4px rgba(blaze-colors.$retailAlt, 0.2);
    }
  }

  &.btn-secondary {
    background-color: blaze-colors.$mono77;
    border-color: blaze-colors.$mono77;

    &:hover {
      background-color: blaze-colors.$mono55;
      border-color: blaze-colors.$mono55;
    }
  }

  &.btn-warning {
    background-color: blaze-colors.$distroAlt;
    border-color: blaze-colors.$distroAlt;

    &:hover,
    &:not(:disabled):not(.disabled):active {
      background-color: blaze-colors.$distro;
      border-color: blaze-colors.$distro;
    }

    &:focus {
      box-shadow: 0 0 0 2px rgba(blaze-colors.$distroAlt, 0.2);
    }

    &.btn-lg:focus {
      box-shadow: 0 0 0 4px rgba(blaze-colors.$distroAlt, 0.2);
    }
  }

  &.btn-danger {
    background-color: blaze-colors.$dangerAlt;
    border-color: blaze-colors.$dangerAlt;

    &:hover,
    &:not(:disabled):not(.disabled):active {
      background-color: blaze-colors.$danger;
      border-color: blaze-colors.$danger;
    }

    &:focus {
      box-shadow: 0 0 0 2px rgba(blaze-colors.$dangerAlt, 0.2);
    }

    &.btn-lg:focus {
      box-shadow: 0 0 0 4px rgba(blaze-colors.$dangerAlt, 0.2);
    }
  }
}

/**
 * Pagination
 */
.page-item {
  &.active {
    .page-link {
      background-color: blaze-colors.$retailAlt;
      border-color: blaze-colors.$retailAlt;
    }
  }
}

/**
 * Select
 */
.Select.is-focused:not(.is-open) > .Select-control {
  border-color: blaze-colors.$retailAlt;
}

.Select-option.is-focused {
  background-color: colors.$blaze-ant-select-background-active;
}

/**
 * Drowdown
 */
.dropdown {
  &-item {
    &:focus, &:hover {
      background-color: colors.$blaze-ant-select-background-active;
    }
  }
}
