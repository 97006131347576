@use '../blaze-theme/blaze-colors.scss';
@use './mixins/button-mixins.scss';


button.ant-btn {
  // color: $retailAlt;
  // border-color: $retailAlt;

  // &:disabled, &:disabled:hover {
  //   @include disabled-button-style($retailAlt, $monoFf, default);
  // }

  // &:hover {
  //   @include hover-button-style($retail, $monoFf, default);
  // }

  // &.ant-btn-default {
  //   color: $retailAlt !important;
  //   border-color: $retailAlt !important;

  //   &.ant-btn-background-ghost {
  //     color: $retailAlt !important;
  //   }

  //   &:disabled, &:disabled:hover {
  //     @include disabled-button-style($retailAlt, $monoFf, default);
  //   }

  //   &:hover {
  //     @include hover-button-style($retail, $monoFf, default);
  //   }
  // }

  // Blaze button styles
  @include button-mixins.stylize-blaze-button(blz-btn-retail, blaze-colors.$monoFf, blaze-colors.$retailAlt, blaze-colors.$retail);
  @include button-mixins.stylize-blaze-button(blz-btn-dispatch, blaze-colors.$monoFf, blaze-colors.$dispatch, blaze-colors.$distroAlt);
  @include button-mixins.stylize-blaze-button(blz-btn-blazepay, blaze-colors.$monoFf, blaze-colors.$blazepay, blaze-colors.$blazepayAlt);
  @include button-mixins.stylize-blaze-button(blz-btn-manufacturing, blaze-colors.$monoFf, blaze-colors.$manufacturing, blaze-colors.$manufacturingAlt);
  @include button-mixins.stylize-blaze-button(blz-btn-grow, blaze-colors.$monoFf, blaze-colors.$grow, blaze-colors.$growAlt);
  @include button-mixins.stylize-blaze-button(blz-btn-distro, blaze-colors.$monoFf, blaze-colors.$distro, blaze-colors.$distroAlt);
  @include button-mixins.stylize-blaze-button(blz-btn-danger, blaze-colors.$monoFf, blaze-colors.$danger, blaze-colors.$dangerAlt);
  @include button-mixins.stylize-blaze-button(blz-btn-black, blaze-colors.$monoFf, blaze-colors.$black, blaze-colors.$blackAlt);

  // Default Ant Design buttons
  @include button-mixins.stylize-blaze-button(ant-btn-primary, blaze-colors.$monoFf, blaze-colors.$retailAlt, blaze-colors.$retail);
  @include button-mixins.stylize-blaze-button(ant-btn-danger, blaze-colors.$monoFf, blaze-colors.$danger, blaze-colors.$dangerAlt);
}

.ant-radio-button-wrapper {
  border-width: 2px;

  &:first-child {
    border-radius: 8px 0 0 8px !important;
  }

  &:last-child {
    border-radius: 0 8px 8px 0 !important;
  }
}

