@use '../../blaze-theme/blaze-colors.scss';

@mixin button-colors($font-color, $background-color, $type) {
  color: $font-color;
  
  @if $type == link {
    background-color: transparent;
  } @else {
    background-color: $background-color;
  }

  @if $type == link {
    border-color: transparent;
  } @else if $type == default {
    // Secondary button style
    border-color: $font-color;
  } @else {
    border-color: $background-color;
  }
}

@mixin disabled-button-style($font-color, $background-color, $type: primary) {
  @include button-colors($font-color, $background-color, $type);
  opacity: 0.4;
}

@mixin hover-button-style($font-color, $background-color, $type: primary) {
  @include button-colors($font-color, $background-color, $type);

  @if $type == link {
    background-color: blaze-colors.$monoFa;
  }
}

@mixin stylize-blaze-button($property, $font-color, $background-color, $hover-color, $type: primary) {
  &.#{$property} {
    @include button-colors($font-color, $background-color, $type);

    @if $type != link {
      &.ant-btn-background-ghost {
        color: $background-color;

        &:disabled, &:disabled:hover {
          @include disabled-button-style($background-color, $background-color);
        }
      
        &:hover {
          @include hover-button-style($background-color, $hover-color, $type);

          background-color: $hover-color !important;
          color: blaze-colors.$monoFf;
        }
      }
    }

    &:disabled, &:disabled:hover {
      @include disabled-button-style($font-color, $background-color, $type);
    }
  
    &:hover {
      @include hover-button-style($font-color, $hover-color, $type);
    }
  }

  &.#{$property}.ant-btn-link {
    @include button-colors($background-color, transparent, link);

    &:disabled, &:disabled:hover {
      @include disabled-button-style($background-color, $background-color, link);
    }
  
    &:hover {
      @include hover-button-style($background-color, $hover-color, link);
    }
  }
}
