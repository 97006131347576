.global-catalog-update {
  .center-div {
    display: flex;
    flex-direction: column;
    position: fixed;
  
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .changeset {
    padding: 16px;

    h1 {
      font-size: 23px;
    }
  }

  .changeset-buttons {
    text-align: right;
    margin-bottom: 24px;

    button:not(:last-child) {
      margin-right: 8px;
    }
  }

  .alert-error {
    padding: 16px;
  }
}