// Blaze brand colours
$black: #29333B;
$blazeBlackSideNav: #303E4E;
$blackAlt: #536578;
$retail: #1CC4E8;
$retailAlt: #2CB2DC;
$distro: #FFAE00;
$distroAlt: #F59F00;
$grow: #42D39A;
$growAlt: #41C396;
$dispatch: #EB8D48;
$dispatchAlt: #DE8C45;
$manufacturing: #9B51E0;
$manufacturingAlt: #914FC4;
$blazepay: #45DCC1;
$blazepayAlt: #46CEBC;
$insights: #FFD500;
$insightsAlt: #F0BC00;
$events: #FF6896;

// Blaze action colours
$danger: #F25E3D;
$dangerAlt: #DB572E;

// Blaze mono colours
$mono20: #202020;
$mono33: #333333;
$mono55: #555555;
$mono77: #777777;
$mono99: #999999;
$monobb: #bbbbbb;
$monocc: #cccccc;
$monodd: #dddddd;
$monoee: #eeeeee;
$monoF5f7fa: #f5f7fa;
$monoFa: #fafafa;
$monoFf: #ffffff;
