@use '../colors.scss';

// Make regular table look like ant design table
.gr-ant-table {
  font-size: 14px;

  th {
    background-color: colors.$offWhite !important;
    color: rgba(0, 0, 0, 0.85) !important;
  }
  
  th, td {
    padding: 8px;
    border-bottom-color: rgb(232, 232, 232) !important;
    border-right-color: rgb(232, 232, 232) !important;
  }
}
