.smart-image {
  width: 100%;
  min-width: 3rem;
  min-height: 3rem;

  &.no-minimum-dimension {
    min-width: unset;
    min-height: unset;
  }
}
