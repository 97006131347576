@use '../styles/blaze-theme/blaze-colors.scss';

// Ant design's `InputNumber` is aesthetically pleasing but very difficult to use for currency. This is to stylise our in-house `CurrencyInput` so it looks like Ant deisng's `InputNumber`
%gr-ant-input-focus {
  border-color: blaze-colors.$retailAlt;
  border-right-width: 1px !important;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(blaze-colors.$retailAlt, 0.2);
  box-shadow: 0 0 0 2px rgba(blaze-colors.$retailAlt, 0.2);
}

.gr-currency-input {
  max-width: 100px;
  height: 32px;
  padding: 0 11px;
  text-align: left;
  background-color: white;
  border: 1px solid blaze-colors.$retailAlt;
  border-radius: 4px;
  transition: all 0.3s;
  
  &:focus {
    @extend %gr-ant-input-focus;
  }
}

.gr-ant-input-focus {
  @extend %gr-ant-input-focus;
}

